import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a className="sel" href="/home/">
                    Strona główna
                  </a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="page_content">
            <div className="wysiwyg uc ac">
            <div className="homepage-heading"><h1>KATAR W PREZENC<span className="ribbon">I</span>E?</h1></div>
              <p>
                Wskaż dokuczające Ci objawy by znaleźć lek odpowiedni dla Ciebie
              </p>
            </div>
            <div className="ac">
              <div className="category">
                <a href="/katar" className="box">
                  <div className="content">
                    <img src="/kcfinder/uploads/files/box/mainpageundermum.png" />{" "}
                  </div>
                  <span
                    className="img"
                    style={{
                      backgroundImage:
                        'url("/kcfinder/uploads/files/box/mama.png")',
                    }}
                  >
                    <span
                      className="hover"
                      style={{
                        backgroundImage:
                          'url("/kcfinder/uploads/files/box/pamper_katar1_hover.png")',
                      }}
                    />
                  </span>
                </a>
                <a href="/katar-i-bol-glowy" className="box">
                  <div className="content">
                    <img src="/kcfinder/uploads/files/box/kataralergicznylarge.png" />{" "}
                  </div>
                  <span
                    className="img"
                    style={{
                      backgroundImage:
                        'url("/kcfinder/uploads/files/box/tata.png")',
                    }}
                  >
                    <span
                      className="hover"
                      style={{
                        backgroundImage:
                          'url("/kcfinder/uploads/files/box/pamper_katar2_hover.png")',
                      }}
                    />
                  </span>
                </a>
                <a href="/katar-u-dzieci" className="box">
                  <div className="content">
                    <img src="/kcfinder/uploads/files/box/mainpageundertheboy.png" />{" "}
                  </div>
                  <span
                    className="img"
                    style={{
                      backgroundImage:
                        'url("/kcfinder/uploads/files/box/boy.png")',
                    }}
                  >
                    <span
                      className="hover"
                      style={{
                        backgroundImage:
                          'url("/kcfinder/uploads/files/box/pamper_katar3_hover.png")',
                      }}
                    />
                  </span>
                </a>
                <div className="clear" />
              </div>
            </div>
            <div className="hp_shortbox">
              <div className="elem">
                <a href="/poradnik/" className="link_box link">
                  <img src="/kcfinder/uploads/files/box/napisporadnik.png" />
                </a>
              </div>
              <div className="elem">
                <a href="/dobierz-lek/" className="link_box link">
                  <img src="/kcfinder/uploads/files/box/zrobtest.png" />
                </a>
              </div>
              
            </div>
            <p
              style={{
                "-webkit-text-align": "right",
                "text-align": "right",
                "font-size": "10px",
                padding: "20px 0 0"
              }}
            >
              1 Dotyczy uczucia ucisku w zatokach spowodowanego nagromadzoną
              wydzieliną i obrzękiem błony śluzowej nosa.&nbsp;
            </p>
          </div>
          <div id="video-layer" className="layer layer-video">
            <div className="close layer__close" />
            <div className="layer__content">
              <div className="layer__content__video">
                <video
                  controls  autoPlay muted
                  width="100%"
                  id="html5_video_3cwn8w8vbtt"
                  poster="/upload/SudafedHAthumbnail.png"
                >
                  <source
                    src="/upload/SUDAFED_HA_Adult_30s_YT.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
              <a href="/produkty/" className="btn">
                SPRAWDŹ
              </a>
              <span className="close close-btn" />
            </div>
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
